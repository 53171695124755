<template>
  <out-layout>
     <header slot="form-header" class="header">
      <h3 class="title" v-if="validated">Conta ativada com sucesso</h3>
      <h3 class="title" v-else>O prazo para ativação da conta expirou</h3>
    </header>

    <div slot="form-content" class="message">
      <v-card-text class="token">
        <img class="icon" src="@/assets/icon-security.svg" alt="Ícone de Segurança">
        <span class="text" v-if="validated">
          Sua conta foi ativada agora você pode acessar a plataforma e concluir seu cadastro.
        </span>
        <span class="text" v-else>
          Clique no botão abaixo e enviaremos no e-mail cadastrado.
        </span>
      </v-card-text>

      <v-card-actions class="action">
        <v-btn color="primary" to="/login" v-if="validated">
          <v-icon left>mdi-account-circle</v-icon>
          <span class="text">ir para o login</span>
        </v-btn>

        <v-btn color="primary" v-else @click="resendEmailValidationToken">
          <v-icon left>mdi-refresh</v-icon>
          <span class="text">Reenviar</span>
        </v-btn>
      </v-card-actions>
    </div>

    <template slot="navigation" v-if="!validated">
      <v-btn flat small color="primary" class="link" :to="'/login'">
        <v-icon left>mdi-account-circle</v-icon>
        <span class="text">Voltar ao login</span>
      </v-btn>
    </template>
  </out-layout>
</template>

<script>
import OutLayout from '@/layouts/OutLayout.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EmailVerification',
  components: {
    OutLayout,
  },
  computed: {
    ...mapGetters({
      validated: 'auth/emailConfirmed',
    }),
  },
  methods: {
    ...mapActions({
      emailVerification: 'auth/emailVerification',
      confirmEmail: 'auth/emailConfirmed',
      setError: 'error/setErrorMessage',
      resendEmailValidationToken: 'auth/resendEmailValidationToken',
    }),
  },
  created() {
    const token = this.$route.params.confirmation_token;
    this.emailVerification(token)
      .then(() => {
        this.confirmEmail(true);
      })
      .catch((err) => {
        this.confirmEmail(false);
        this.setError(err);
      });
  },
};
</script>

<style scoped lang="scss">
.header {
  > .title {
    font-family: "Nexa", sans-serif !important;
    font-weight: 400;
    font-size: 1.125rem !important;
    color: #396753;
  }
}
.message {
  > .token {
    & {
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    > .icon {
      margin-right: 2rem;
      width: 60px;
    }
  }
}

.navigation {
  > .link {
    text-transform: none;
  }
}
</style>
