var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "out-layout",
    [
      _c(
        "header",
        {
          staticClass: "header",
          attrs: { slot: "form-header" },
          slot: "form-header",
        },
        [
          _vm.validated
            ? _c("h3", { staticClass: "title" }, [
                _vm._v("Conta ativada com sucesso"),
              ])
            : _c("h3", { staticClass: "title" }, [
                _vm._v("O prazo para ativação da conta expirou"),
              ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "message",
          attrs: { slot: "form-content" },
          slot: "form-content",
        },
        [
          _c("v-card-text", { staticClass: "token" }, [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: require("@/assets/icon-security.svg"),
                alt: "Ícone de Segurança",
              },
            }),
            _vm.validated
              ? _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n        Sua conta foi ativada agora você pode acessar a plataforma e concluir seu cadastro.\n      "
                  ),
                ])
              : _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n        Clique no botão abaixo e enviaremos no e-mail cadastrado.\n      "
                  ),
                ]),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "action" },
            [
              _vm.validated
                ? _c(
                    "v-btn",
                    { attrs: { color: "primary", to: "/login" } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-account-circle"),
                      ]),
                      _c("span", { staticClass: "text" }, [
                        _vm._v("ir para o login"),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.resendEmailValidationToken },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-refresh"),
                      ]),
                      _c("span", { staticClass: "text" }, [_vm._v("Reenviar")]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.validated
        ? _c(
            "template",
            { slot: "navigation" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "link",
                  attrs: {
                    flat: "",
                    small: "",
                    color: "primary",
                    to: "/login",
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-account-circle"),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _vm._v("Voltar ao login"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }